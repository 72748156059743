import React from 'react'
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage"
import AboutPage from './pages/about';
import Faq from './pages/faq';
import Contact from './pages/Contact';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
