import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import SideMenu from '../components/SideMenu';
import MenuItem from '../components/MenuItem';
import 'aos/dist/aos.css';

export default function AboutPage() {
  const navigate = useNavigate()

  const handleClick = () => {
    const url = 'https://bit.ly/49LKjny';
    window.open(url, '_blank');
  }

  const handleInstaClick = () => {
    const url = "https://www.instagram.com/gotomykreeb?igsh=MXhpcGl3cG84bTRodg=="
    window.open(url, '_blank')
  }

  const handleLinkedClick = () => {
    const url = "https://www.linkedin.com/company/mykreeb-africa/"
    window.open(url, '_blank')
  }

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='bg-[#303345]'>
      <div className='mb-[40px] lg:px-[150px] pl-[20px] lg:pt-[30px] pt-[20px] flex justify-between items-center'>
        <div className='flex cursor-pointer' onClick={(() => navigate('/'))}>
          <img src={'/images/klogo.png'} className='lg:h-[50px] h-[30px] lg:w-[60px] w-[40px]' alt='logo' />
          <span className='text-white lg:text-[30px] text-[20px] font-[600]'>MyKreeb</span>
        </div>
        <SideMenu />
        <MenuItem />
      </div>
      <p className='text-white lg:text-[64px] text-[40px] font-[600] text-center' data-aos="zoom-in" data-aos-duration="2000">About Us</p>

      <p className='text-white lg:text-[48px] text-[36px] font-[600] bg-[#171621] lg:mx-[150px] mx-[20px] pl-[100px] py-[10px] mt-[70px] rounded-[45px]'>Summary</p>
      <div className='text-white lg:text-[30px] text-[24px] lg:mx-[180px] mx-[20px] mt-[30px] flex flex-col gap-[30px]'>
        <p data-aos="zoom-in" data-aos-duration="2000">
          MyKreeb is a Property-tech startup transforming Africa's real estate industry with an all-in-one digital marketplace, and innovative solutions leveraging artificial intelligence, machine learning, and big data.
        </p>
        <p data-aos="zoom-in" data-aos-duration="2000">It connects property owners and users, addressing the stress in property search and reducing middleman-ship. </p>
        <p data-aos="zoom-in" data-aos-duration="2000">Innovative features like a savings wallet, an AI enhance user experience, map mode explore, comprehensive neighborhood data and virtual inspection.

        </p>
        <p data-aos="zoom-in" data-aos-duration="2000">With a promising brand, experienced team, and comprehensive plans, MyKreeb is poised to revolutionize property transactions in Africa.</p>
      </div>

      <div className='flex lg:flex-row flex-col gap-[30px] lg:mx-[180px] mx-[20px] text-white mt-[100px]'>
        <div className='lg:w-[50%] bg-[#171621] lg:rounded-[85px] rounded-[50px] lg:h-[768px] py-[40px]' data-aos="zoom-in" data-aos-duration="2000">
          <p className='text-center lg:text-[48px] text-[40px] font-[600] text-[#FEB800] mb-[10px]'>Mission</p>
          <p className='lg:text-[30px] text-[24px] lg:px-[60px] px-[30px]'>To empower property buyers
            and sellers in Africa by
            providing an innovative
            digital marketplace that offers
            a wide variety of properties,
            eliminates unnecessary intermediaries, and makes the
            process of finding and securing
            a property more accessible and affordable.</p>
        </div>
        <div className='flex flex-col lg:w-[50%]'>
          <div className='bg-[#171621] lg:rounded-[85px] rounded-[50px] lg:h-[368px] py-[40px]' data-aos="zoom-in" data-aos-duration="2000">
            <p className='text-center lg:text-[48px] text-[40px] font-[600] text-[#FEB800] mb-[10px]'>Vision</p>
            <p className='lg:text-[30px] text-[24px] lg:px-[60px] px-[30px]'>
              To transform property
              transactions through a
              user-centric and tech-driven platform.
            </p>
          </div>
          <div className='bg-[#171621] rounded-[85px] h-[368px] pt-[40px] mt-[40px]' data-aos="zoom-in" data-aos-duration="2000">
            <p className='text-center lg:text-[48px] text-[40px] font-[600] text-[#FEB800] mb-[10px]'>Core Values</p>
            <p className='lg:text-[30px] text-[24px] lg:px-[60px] px-[30px]'>
              -  Thinking Different <br />
              -  Customer Obsession <br />
              -  Transparency <br />
              -  Innovation <br />
            </p>
          </div>
        </div>
      </div>

      <div className='bg-[#171621]'>
        <div className='pt-[30px] mt-[40px] mx-[20px]'>
          <p onClick={handleClick} className='cursor-pointer text-white bg-[#303345] lg:py-[30px] py-[10px] z-70 text-center lg:text-[30px] text-[24px] lg:mx-[500px] rounded-[30px]'>JOIN THE WAITING LIST</p>
        </div>
        <div className='flex lg:flex-row flex-col lg:gap-0 gap-[30px] text-white lg:pt-[100px] pt-[50px] lg:justify-between lg:items-center lg:px-[250px] lg:text-[24px] text-[18px] text-center'>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Company</div>
            <div>About</div>
            <div>Careers</div>
            <div>Terms</div>
            <div>Partners</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Links</div>
            <div>News & Media</div>
            <div>Community</div>
            <div>Realtors</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Contact</div>
            <div>welcome@mykreeb.com</div>
            <div>+234(0)9039106777</div>

            <div className='flex gap-[10px] items-center justify-center  mt-[30px]'>
              <img onClick={handleInstaClick} src={'/images/instagram.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img onClick={handleLinkedClick} src={'/images/linkedin.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/facebook.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/twitter.png'} className='h-[47px] w-[47px]' alt='logo' />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#171621] py-[40px] text-white text-center text-[14px]'>
        <p>© 2024 MyKreeb Africa Ltd. (Company No.11472232). All rights reserved.</p>
      </div>
    </div>
  )
}
