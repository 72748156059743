import React from 'react';
import { useNavigate } from 'react-router-dom'
import SideMenu from '../components/SideMenu';
import MenuItem from '../components/MenuItem';

export default function Faq() {
  const navigate = useNavigate()

  const handleClick = () => {
    const url = 'https://bit.ly/49LKjny';
    window.open(url, '_blank');
  }

  const handleInstaClick = () => {
    const url = "https://www.instagram.com/gotomykreeb?igsh=MXhpcGl3cG84bTRodg=="
    window.open(url, '_blank')
  }

  const handleLinkedClick = () => {
    const url = "https://www.linkedin.com/company/mykreeb-africa/"
    window.open(url, '_blank')
  }

  return (
    <>
      <div className='bg-[#303345]'>
      <div className='mb-[40px] lg:px-[150px] pl-[20px] lg:pt-[30px] pt-[20px] flex justify-between items-center'>
        <div className='flex cursor-pointer' onClick={(() => navigate('/'))}>
        <img src={'/images/klogo.png'} className='lg:h-[50px] h-[30px] lg:w-[60px] w-[40px]' alt='logo' />
        <span className='text-white lg:text-[30px] text-[20px] font-[600]'>MyKreeb</span>
        </div>
        <SideMenu />
        <MenuItem />
      </div>
      <p className='text-white lg:text-[64px] text-[40px] font-[600] text-center' data-aos="zoom-in" data-aos-duration="2000">Frequently Asked Questions</p>
      <FaqItem question="1. What makes MyKreeb different from other real estate platforms?" answer="MyKreeb uses cutting-edge tech for transparent property transactions." />
      <FaqItem question="2 Can I list my property on MyKreeb?" answer="Yes, easily list your property and manage it on MyKreeb." />
      <FaqItem question="3 Is MyKreeb available nationwide?" answer="MyKreeb is expanding nationwide to cover diverse user needs." />
      <FaqItem question="4 Can I search for properties based on specific criteria?" answer="Yes, MyKreeb offers advanced search filters allowing you to find properties based on location, price range, property type, and more." />
      <FaqItem question="5 How does MyKreeb ensure user data security?" answer="MyKreeb employs top-notch encryption and strict access controls." />

      <div className='bg-[#171621]'>
        <div className='pt-[30px] mt-[40px] mx-[20px]'>
          <p onClick={handleClick} className='cursor-pointer text-white bg-[#303345] lg:py-[30px] py-[10px] z-70 text-center lg:text-[30px] text-[24px] lg:mx-[500px] rounded-[30px]'>JOIN THE WAITING LIST</p>
        </div>
        <div className='flex lg:flex-row flex-col lg:gap-0 gap-[30px] text-white lg:pt-[100px] pt-[50px] lg:justify-between lg:items-center lg:px-[250px] lg:text-[24px] text-[18px] text-center'>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Company</div>
            <div>About</div>
            <div>Careers</div>
            <div>Terms</div>
            <div>Partners</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Links</div>
            <div>News & Media</div>
            <div>Community</div>
            <div>Realtors</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Contact</div>
            <div>welcome@mykreeb.com</div>
            <div>+234(0)9039106777</div>

            <div className='flex gap-[10px] items-center justify-center  mt-[30px]'>
              <img onClick={handleInstaClick} src={'/images/instagram.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img onClick={handleLinkedClick} src={'/images/linkedin.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/facebook.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/twitter.png'} className='h-[47px] w-[47px]' alt='logo' />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#171621] py-[40px] text-white text-center text-[14px]'>
        <p>© 2024 MyKreeb Africa Ltd. (Company No.11472232). All rights reserved.</p>
      </div>
      </div>

    </>
  )
}

const FaqItem = ({question, answer}) => {
  return (
    <div className='lg:my-[100px] my-[40px]'>
      <p className='text-white lg:text-[36px] text-[20px] font-[600] bg-[#171621] lg:mx-[150px] mx-[20px] lg:px-[100px] px-[20px] py-[10px] lg:mt-[70px] mt-[20px] lg:rounded-[45px] rounded-[20px]'>{question}</p>
      <p className='text-white lg:text-[30px] text-[16px] lg:px-[250px] px-[20px] mt-[10px] lg:text-left text-justify' data-aos="fade-right" data-aos-duration="2000">{answer}</p>
  </div>
  )
}
