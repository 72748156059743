import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function MenuItem() {
  const navigate = useNavigate()
  return (
    <div className='hidden lg:flex text-[20px] gap-[30px] bg-[#303345] text-white items-center justify-center rounded-[60px] py-[10px] px-[50px]'>
      <div onClick={() => navigate('/about')} className='cursor-pointer'>About</div>
      <div onClick={() => navigate('/faq')} className='cursor-pointer'>FAQs</div>
      <div onClick={() => navigate('/contact')} className='cursor-pointer'>Contact</div>
      {/* <div>Sign Up</div> */}
    </div>
  )
}
