import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import SideMenu from '../components/SideMenu'
import MenuItem from '../components/MenuItem'
import 'aos/dist/aos.css';


export default function Contact() {
  const navigate = useNavigate()
  const handleClick = () => {
    const url = 'https://bit.ly/49LKjny';
    window.open(url, '_blank');
  }

  const handleInstaClick = () => {
    const url = "https://www.instagram.com/gotomykreeb?igsh=MXhpcGl3cG84bTRodg=="
    window.open(url, '_blank')
  }

  const handleLinkedClick = () => {
    const url = "https://www.linkedin.com/company/mykreeb-africa/"
    window.open(url, '_blank')
  }

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='bg-[#171621] h-screen'>
      <div className='mb-[40px] lg:px-[150px] pl-[20px] lg:pt-[30px] pt-[20px] flex justify-between items-center'>
        <div className='flex cursor-pointer' onClick={(() => navigate('/'))}>
          <img src={'/images/klogo.png'} className='lg:h-[50px] h-[30px] lg:w-[60px] w-[40px]' alt='logo' />
          <span className='text-white lg:text-[30px] text-[20px] font-[600]'>MyKreeb</span>
        </div>
        <SideMenu />
        <MenuItem />
      </div>

      <div className='lg:text-center text-white'>
        <p className='text-[45px] font-[600] text-center mt-[100px]' data-aos="zoom-in" data-aos-duration="2000">Contact</p>
        <div className='mx-[65px] lg:mx-0'>
          <div className='flex items-center justify-center py-[10px] lg:gap-[15px] gap-[10px]' data-aos="zoom-in" data-aos-duration="2000">
            <img src={'/images/letter.png'} className='lg:h-[30px] h-[22px] lg:w-[30px] w-[22px]' alt='email' />
            <p className='text-[24px]'>welcome@mykreeb.com</p>
          </div>

          <div className='flex items-center justify-center py-[10px] lg:gap-[15px] gap-[10px] text-justify' data-aos="zoom-in" data-aos-duration="2000">
            <img src={'/images/ringer.png'} className='lg:h-[30px] h-[22px] lg:w-[30px] w-[22px]' alt='email' />
            <div className='text-[24px]'>
              <p className=''>+234(0)9039106777,</p>
              <p>+234 809 968 5309</p>
            </div>
          </div>

          <div className='flex items-center justify-center py-[10px] lg:gap-[15px] gap-[10px]' data-aos="zoom-in" data-aos-duration="2000">
            <img src={'/images/maker.png'} className='lg:h-[30px] h-[22px] lg:w-[30px] w-[22px]' alt='email' />
            <p className='text-[24px]'>CafeOne, Chevron drive, Lekki Lagos, Nigeria.</p>
          </div>
        </div>

        <div className='flex gap-[10px] items-center justify-center  mt-[60px]' data-aos="zoom-in" data-aos-duration="2000">
          <img onClick={handleInstaClick} src={'/images/instagram.png'} className='h-[37px] w-[37px]' alt='logo' />
          <img onClick={handleLinkedClick} src={'/images/linkedin.png'} className='h-[37px] w-[37px]' alt='logo' />
          <img src={'/images/facebook.png'} className='h-[37px] w-[37px]' alt='logo' />
          <img src={'/images/twitter.png'} className='h-[47px] w-[47px]' alt='logo' />
        </div>
      </div>

      <div className='mt-[100px] mx-[40px] lg:mx-0'>
        <p onClick={handleClick} className='cursor-pointer text-white bg-[#303345] lg:py-[30px] py-[10px] z-70 text-center lg:text-[30px] text-[20px] lg:mx-[500px] rounded-[30px] px-[20px]'>JOIN THE WAITING LIST</p>
      </div>

      <div className='bg-[#171621] pt-[120px] text-white text-center text-[14px]'>
        <p>© 2024 MyKreeb Africa Ltd. (Company No.11472232). All rights reserved.</p>
      </div>
    </div>
  )
}
