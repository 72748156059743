import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import Frame1 from '../components/frame1';
import MenuItem from '../components/MenuItem';
import SideMenu from '../components/SideMenu';
import 'aos/dist/aos.css';

export default function HomePage() {
  const navigate = useNavigate()
  const imageUrl = '/images/bg1.png';

  const handleClick = () => {
    const url = 'https://bit.ly/49LKjny';
    window.open(url, '_blank');
  }

  const handleInstaClick = () => {
    const url = "https://www.instagram.com/gotomykreeb?igsh=MXhpcGl3cG84bTRodg=="
    window.open(url, '_blank')
  }

  const handleLinkedClick = () => {
    const url = "https://www.linkedin.com/company/mykreeb-africa/"
    window.open(url, '_blank')
  }

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className='overflow-x-hidden'>
      <div className='pb-[40px] bg-[#171621] lg:px-[150px] px-[20px] lg:pt-[30px] pt-[20px] flex justify-between items-center w-[100%'>
        <div className='flex cursor-pointer' onClick={(() => navigate('/'))}>
          <img src={'/images/klogo.png'} className='lg:h-[50px] h-[30px] lg:w-[60px] w-[40px]' alt='logo' />
          <span className='text-white lg:text-[30px] text-[20px] font-[600]'>MyKreeb</span>
        </div>
        <SideMenu />
        <MenuItem />
      </div>
      <div className='flex lg:flex-row flex-col' style={{ backgroundImage: `url(${imageUrl})`}}>
        <div className='lg:w-[50%] flex flex-col  lg:pt-[100px] lg:pl-[180px] px-[20px] lg:px-0' data-aos="zoom-in" data-aos-duration="2000">
          <div className='text-white lg:text-[65px] text-[35px] font-[700]'>
            <p>The Property</p>
            <p>Search Engine</p>
            <p className='text-[#FEB900] font-[900]'>of Africa.</p>
          </div>

          <p className='text-white lg:text-[30px] text-[20px]  mt-[20px]'>Transforming the way you discover real estate properties.</p>

            <p onClick={handleClick} className='text-white bg-[#303345] py-[20px] mt-[30px] px-[30px] text-center lg:text-[30px] text-[24px] rounded-[30px] whitespace-nowrap cursor-pointer'>Sign up</p>

        </div>
        <div className='lg:w-[50%] mt-[100px]' data-aos="zoom-in" data-aos-duration="2000">
          <img src={'/images/hero1.png'} className='w-[100%] ' alt='logo' />
        </div>
      </div>

      <div className='flex flex-col items-center justify-center text-white lg:py-[100px] py-[50px] lg:px-[250px] mx-[20px]'>
        <p className='lg:text-[20px] text-[16px] text-[#828282] text-center' data-aos="zoom-in" data-aos-duration="2000">SEARCH AND DISCOVER PROPERTIES WITHOUT STRESS</p>
        <p className='lg:text-[50px] text-[20px] font-[600] lg:mx-[20px]' data-aos="zoom-in" data-aos-duration="2000">Finding a property has never</p>
        <p className='lg:text-[50px] text-[20px] font-[600] lg:mx-[20px]' data-aos="zoom-in" data-aos-duration="2000">been this <span className='font-[800]'>EASY</span></p>
        <div className='flex lg:flex-row flex-col gap-[50px] justify-center items-center mt-[40px]'>
          <Frame1 count="one" className='lg:h-[96px] h-[55px] lg:w-[52px] w-[30px]' image={"/images/vector1.png"} title={"Direct access to Landlords and Verified Agents"} subtitle={'Saves you from the fear of being frauded.'} />
          <Frame1 count="two" className='lg:h-[85px] h-[47px] lg:w-[85px] w-[47px]' image={"/images/vector2.png"} title={"Detailed Data on properties and neighborhoods"} subtitle={'To streamline your choices for a property and location.'} />
          <Frame1 count="three" className='lg:h-[89px] h-[47px] lg:w-[89px] w-[47px]' image={"/images/vector3.png"} title={"AI and big data tech for property insights & predictive analysis"} subtitle={'AI-driven insights: flood-prone detection, predictive analysis & security.'} />
        </div>
      </div>

      <div className='lg:h-[824px] pb-[60px] flex lg:flex-row flex-col gap-[20px] lg:px-[250px] px-[20px]' style={{ backgroundImage: "url('/images/bg2.png')" }}>
        <div className='text-white lg:w-[50%] lg:pt-[250px] pt-[150px]' data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
          <p className='lg:text-[48px] text-[40px] font-[700] text-center rounded-[30px] py-[10px] bg-[#6609C9]'>Property Variety</p>
          <p className='lg:text-[30px] text-[24px] py-[20px]'>Access to a wide range of property categories, both residential and commercial properties.</p>
          <p className='lg:text-[20px] text-[16px]'>Properties available for rent, sale, short-let, shopping malls, co-working office spaces, event centers, etc.</p>
        </div>
        <div data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
          <img src={'/images/frame1.png'} className='lg:h-[668px] h-[368px] lg:w-[655px] w-[455px]' alt='logo' />
        </div>
      </div>

      <div className='py-[100px] flex lg:flex-row flex-col-reverse gap-[50px] lg:px-[250px]'>
        <div data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
          <img src={'/images/frame2.png'} className='lg:h-[650px] h-[450px] lg:w-[655px] w-[455px]' alt='logo' />
        </div>
        <div className='text-white lg:w-[50%] mx-[20px] lg:pt-[250px]' data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1000">
          <p className='text-[48px] font-[700] text-center rounded-[30px] py-[10px] bg-[#6609C9]'>Map View</p>
          <p className='lg:text-[30px] text-[24px] py-[20px]'>Explore properties on map mode, compare prices in different areas</p>
          <p className='lg:text-[20px] text-[16px]'>Ease of access to a wide variety of properties with an innovative search and discovery interface, exploring properties on map mode.</p>
        </div>
      </div>

      <div className='py-[100px] flex lg:flex-row flex-col gap-[50px] lg:pl-[250px] bg-[#4b1d87]'>
        <div className='text-white lg:w-[50%] lg:pt-[50px] mx-[20px]' data-aos="zoom-in" data-aos-duration="2000">
          <p className='text-[48px] font-[700] text-center rounded-[30px] py-[10px] bg-[#6609C9]'>Virtual Tour</p>
          <p className='lg:text-[30px] text-[24px] py-[20px]'>Virtually inspect properties from the comfort of your home, anywhere in the world.</p>
          <p className='lg:text-[20px] text-[16px]'>Tour properties virtually integrated with google street view to inspect how the street looks like.</p>
        </div>
        <div data-aos="zoom-in" data-aos-duration="2000">
          <img src={'/images/frame3.png'} className='lg:h-[495px] h-[295px] w-[806px]' alt='logo' />
        </div>
      </div>

      <div className=' flex lg:flex-row flex-col-reverse lg:gap-[50px] gap-[30px] lg:px-[250px] px-[20px]'>
        <div data-aos="zoom-in" data-aos-duration="2000">
          <img src={'/images/frame4.png'} className='lg:h-[587px] h-[387px] lg:w-[655px] w-[455px]' alt='logo' />
        </div>
        <div className='text-white lg:w-[50%] lg:pt-[150px] pt-[100px]' data-aos="zoom-in" data-aos-duration="2000">
          <p className='lg:text-[48px] text-[40px] font-[700] text-center rounded-[30px] py-[10px] bg-[#6609C9]'>Savings Wallet</p>
          <p className='lg:text-[30px] text-[24px] py-[20px]'>Automated savings wallet for your rent and property purchase.</p>
          <p className='lg:text-[20px] text-[16px]'>This enables users to save towards their rent or property purchase, with a convenient, flexible and automated payment option for users.</p>
        </div>
      </div>
      <div className='lg:relative' data-aos="zoom-in" data-aos-duration="2000">
        <div className='lg:absolute  lg:pt-[550px] pt-[100px] lg:pl-[400px] flex flex-col  justify-center '>
          <p className=' lg:w-[60%] lg:text-[64px] text-[40px] text-white font-[600] lg:text-left text-center'>APP WILL BE AVAILABLE SOON</p>
          <img className='h-[62px] w-[351px] ml-[40px] lg:ml-0' src={'/images/frame6.png'} alt='app' />
        </div>
        <img className='lg:h-[1000px] h-[500] w-[100%] -lg:mt-[150px] -mt-[50px]' src={'/images/frame5.png'} alt='logo' />
      </div>
      <div className='lg:h-[810px] bg-[#171621] -mt-[300px]'>
        <div className='pt-[300px]' data-aos="zoom-in" data-aos-duration="2000">
          <p onClick={handleClick} className='text-white bg-[#303345] lg:py-[30px] py-[10px] z-70 text-center lg:text-[30px] text-[24px] lg:mx-[500px] rounded-[30px] cursor-pointer'>Sign up</p>
        </div>
        <div className='flex lg:flex-row flex-col lg:gap-0 gap-[30px] text-white lg:pt-[100px] pt-[50px] lg:justify-between lg:items-center lg:px-[250px] lg:text-[24px] text-[18px] text-center'>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Company</div>
            <div>About</div>
            <div>Careers</div>
            <div>Terms</div>
            <div>Partners</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Links</div>
            <div>News & Media</div>
            <div>Community</div>
            <div>Realtors</div>
          </div>
          <div className='lg:w-[35%]'>
            <div className='text-[#FEB900] font-[500]'>Contact</div>
            <div>welcome@mykreeb.com</div>
            <div>+234(0)9039106777</div>

            <div className='flex gap-[10px] items-center justify-center  mt-[30px]'>
              <img onClick={handleInstaClick} src={'/images/instagram.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img onClick={handleLinkedClick} src={'/images/linkedin.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/facebook.png'} className='h-[37px] w-[37px]' alt='logo' />
              <img src={'/images/twitter.png'} className='h-[47px] w-[47px]' alt='logo' />
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#171621] py-[40px] text-white text-center text-[14px]'>
        <p>© 2024 MyKreeb Africa Ltd. (Company No.11472232). All rights reserved.</p>
      </div>
    </div>
  )
}
