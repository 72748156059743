import React from 'react'

export default function Frame1({image, title, subtitle, className, count}) {
  return (
    <div data-aos="zoom-in" data-aos-delay="1000" data-aos-duration={count === "one" ? 2000 : count === "two" ? 3000 : 4000} className='flex lg:justify-start lg:items-start items-center justify-center flex-col'>
      <div className='flex items-center justify-center bg-[#171621] lg:rounded-[50px] rounded-[30px] lg:h-[200px] h-[101px] lg:w-[200px] w-[120px] border mb-[30px]'>
      <img src={image} className={`${className}`} alt='logo' />
      </div>

      <p className='lg:text-[24px] text-[20px] font-[600] mb-[20px] lg:text-left text-center'>{title}</p>
      <p className='lg:text-[20px] text-[16px] lg:text-left text-center'>{subtitle}</p>
    </div>
  )
}
