import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`lg:hidden block fixed top-0 right-0 h-full bg-[#313245] shadow-lg transition-all duration-300 z-10 ${isOpen ? 'w-64' : 'w-0'}`}>
      <button className="absolute top-4 right-4 text-gray-500" onClick={toggleMenu}>
        {isOpen ? <button
          className="text-white focus:outline-none"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg></button>
          : <button
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>}
      </button>
      {isOpen && (
         <div className="pt-16 px-4">
         <ul className="space-y-6">
           <li  onClick={(() => {navigate('/'); setIsOpen(!isOpen)})} className="text-white cursor-pointer lg:text-[20px] text-[16px] bg-[#171621] pl-[20px] py-[5px] rounded-[11px]">Home</li>
           <li  onClick={(() => navigate('/about'))} className="text-white cursor-pointer lg:text-[20px] text-[16px] bg-[#171621] pl-[20px] py-[5px] rounded-[11px]">About</li>
           <li onClick={(() => navigate('/faq'))} className="text-white cursor-pointer lg:text-[20px] text-[16px] bg-[#171621] pl-[20px] py-[5px] rounded-[11px]">FAQs</li>
           <li onClick={(() => navigate('/contact'))} className="text-white cursor-pointer lg:text-[20px] text-[16px] bg-[#171621] pl-[20px] py-[5px] rounded-[11px]">Contact</li>
         </ul>
       </div>
      )}

    </div>
  );
};

export default SideMenu;
